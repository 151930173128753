import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container py-5">
      <div className="row">
        <div className="col-12">
          <div className="section-title">
            <h1>Oops! 404: Not Found</h1>
          </div>
          <div>
            <p className="text-center">
              You just hit a route that doesn't exist........sadness.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
